//
//
//
//
//
//
//
//
//
//
//

import ListContainer from '@/components/ListContainer'
export default {
    name: "EventAbout",
    props:['description', 'achievements','eventData'],
    components: { ListContainer },
    data() {
      return {
        about: [
          {
            id: 3,
            title: 'Green Fee',
            value: (this.eventData.green_fee == 0) ? 'Free' : this.eventData.green_fee,
            imgsrc: require('@/assets/event/green-fee.png'),
            pesoSign: true
          },
          {
            id: 4,
            title: 'Caddie Fee',
            value: (this.eventData.caddie_fee == 0) ? 'Free' : this.eventData.caddie_fee,
            imgsrc: require('@/assets/event/caddie-fee.png'),
            pesoSign: true
          },
          {
            id: 0,
            title: (this.eventData.venue !== null) ? 'Venue Location' : 'Golf Course',
            value: (this.eventData.venue !== null) ? this.eventData.venue : this.eventData.course_name,
            imgsrc: require('@/assets/event/course.png')
          },
          {
            id: 1,
            title: 'System Format',
            value: (this.eventData.system_format == '') ? 'Not specified' : this.eventData.system_format,
            imgsrc: require('@/assets/event/system-format.png')
          },
          {
            id: 2,
            title: 'System Scoring',
            value: (this.eventData.system_scoring == '') ? 'Not specified' : this.eventData.system_scoring,
            imgsrc: require('@/assets/event/system-scoring.png')
          },
          {
            id: 5,
            title: 'Hole In One Prize',
            value: (this.eventData.hole_in_one_prize == '') ? 'No Prize' : this.eventData.hole_in_one_prize,
            imgsrc: require('@/assets/event/hole-in-one-prize.png')
          },
          {
            id: 6,
            title: 'First Prize',
            value: (this.eventData.first_prize == '') ? 'No Prize' : this.eventData.first_prize,
            imgsrc: require('@/assets/event/first-prize.png')
          },
          {
            id: 7,
            title: 'Second Prize',
            value: (this.eventData.second_prize == '') ? 'No Prize' : this.eventData.second_prize,
            imgsrc: require('@/assets/event/second-prize.png')
          },
          {
            id: 8,
            title: 'Third Prize',
            value: (this.eventData.third_prize == '') ? 'No Prize' : this.eventData.third_prize,
            imgsrc: require('@/assets/event/third-prize.png')
          },
        ]
      }
    }
}
