//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ListContainer',
    props: ['listData']
}
